import { render, staticRenderFns } from "./AskDwgView.vue?vue&type=template&id=fc57aff2&scoped=true&"
import script from "./AskDwgView.vue?vue&type=script&lang=js&"
export * from "./AskDwgView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc57aff2",
  null
  
)

export default component.exports